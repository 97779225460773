<template>
    <v-text-field :placeholder="config.placeholder" :label="config.label"
                  v-model="model[config.key]"
                  v-on="config.customEvents" :required="config.required"
                  :maxlength="config.maxlength" :rules="config.rules" :type="config.type"
                  :error-messages="errorMessages"
                  :disabled="isDisabled"
                  :dense="config.dense"
                  :readonly="isReadonly">
    </v-text-field>
</template>

<script>

export default {
    name: 'FormField',
    props: ['config', 'model', 'errors'],
    computed: {
        errorMessages() {
            if (this.errors) {
                return this.errors[this.config.key];
            }
            return '';
        },

        isReadonly() {
            return this.config.isReadonly && this.config.isReadonly(this.model);
        },

        isDisabled() {
            return this.config.isDisabled && this.config.isDisabled(this.model);
        }
    }
}
</script>
